<template>
  <div class="orderManagementDetailsPageBox page-info-content">
    <Details-page :detail-obj="detailObj" :details-item-arr="detailsItemArr" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { outDetail } from '@/api/transactionData'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: {},
      detailsItemArr: [
        { label: '合同编号', value: 'contractNo' },
        { label: '出库编号', value: 'outNo' },
        { label: '品名', value: 'goodsName', width: 200 },
        { label: '材质', value: 'goodsMaterials', width: 200 },
        { label: '规格', value: 'goodsSpecs', width: 200 },
        { label: '产地', value: 'goodsProduct', width: 200 },
        { label: '件重(吨)', value: 'goodsWeights' },
        // { label: '出库数量', value: 'outAmount' },
        { label: '出库数量/单位', value: 'outWeight' },
        { label: '长度(米)', value: 'length' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    getDetails() {
      outDetail(this.$route.query.id, res => {
        const unitArr = this.$store.getters.getDictionaryItem('purchaseUnit') || []
        let unitObj = { dictName: '吨' }
        if (res.data.goodsUnit) unitObj = unitArr.find(val => Number(val.dictId) === Number(res.data.goodsUnit))
        res.data.outWeight = (res.data.outWeight || 0) + unitObj.dictName
        this.detailObj = { ...res.data }
      })
    }
  }
}
</script>
